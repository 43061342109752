@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Poppins:wght@400;500;600&display=swap');
/*Base*/
*{margin:0;
  padding:0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}
body, button, input, textarea{
 font-family: 'Poppins', sans-serif;;
}

body{
  background-color: rgb(201, 225, 239);
  color:rgb(10, 48, 64);
}

h1, h2, h3{
  color: rgb(14, 110, 123);
}

ul{
  list-style: none;
}
a{
  text-decoration: none;
}

button{
  cursor: pointer;
  outline:none;
  border:none;
}

img{
  max-width: 100%;
  height:auto;
}

/*Resusable CSS Classes*/
.section{
  padding:6rem 2rem;
}

.section-about{
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 5rem;
}
.home-section{
  padding-top: 1rem;
}



.section-title{
  font-size: 2rem;
  color: rgb(14, 110, 123);
}

.section-subtitle{
  display:block;
  font-size: 1.5rem;
  margin-bottom: 4rem;
}

.qualifications-subtitle{
  margin-bottom: 1rem;
}

.section-subtitle1{
  margin-bottom: 2rem;
}

.section-title, .section-subtitle{
  text-align: center;
}

/*LAYOUT*/

.container{
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
  /* justify-items: center; */
  text-align: center;
}


.grid{
  display: grid;
  gap:1.5rem;
}
/*Buttons*/

.home-container {
  row-gap: 3rem;
}
.button{
  display: inline-block;
  background-color: rgb(10, 48, 64);
  color:white;
  transition: 0.5s;
  padding: 0.3rem 0.6rem;

  border-color: rgb(10, 48, 64);
  
};


.button:hover {
  color: rgb(10, 48, 64);
  background-color: rgba(10, 48, 64, 0);
}

.button-icon{
  margin-left: 1rem;
}

.button-flex{
  display: inline-flex;
  align-items: center;
}



.nav{
  padding:1rem 0;
  border-bottom: 0.15rem solid rgb(10, 48, 64);
  border-bottom: 0.15rem solid rgb(217, 126, 41);
  background-color: rgb(232, 239, 246);
  box-shadow: 0 0 0.3rem black;

}
.nav-center{
  width:90vw;
  max-width: 1170px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;
}



.nav-link{
  padding:0.3rem 0;
}

.nav-item{
  color: rgb(10, 48, 64);
transition: 0.5s;
position: relative;
}

.nav-item:hover {
  color: rgb(217, 126, 41);

}

.nav-item::after{
  content: '';
  background-color:rgb(217, 126, 41) ;
  position: absolute;
  width: 100%;
  height: 0.2rem;
  left:0;
  top:1.5rem;
  width: 0%;
  transition: 0.5s;
}

.nav-item:hover::after {
  width: 100%;
}

.lsw{
  color: rgb(217, 126, 41);
  text-shadow: 0.08rem -0.08rem 0 rgb(10, 48, 64);;
}
.active{
  color:rgb(217, 126, 41);
}

.nav-logo{
  text-shadow: 0.08rem -0.08rem 0 rgb(217, 126, 41);
}

.nav-header{
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-toggle, .nav-close{
  cursor: pointer;
  font-size: 2rem;
}

.nav-container{
  height:0;
  overflow: hidden; 
}

.nav-container{
  transition: 0.5s;
}

.hide{
  display: none;
}

@media screen and (min-width:800px){

  .nav-links {
      display: flex;
      justify-content: space-between;
      /* background-color: blueviolet; */
    
    }

  .nav-toggle, .nav-close{
    display: none;
  }

  .nav-header{
    width:auto;
  }

.nav-link{
  margin-left: 2rem;
  padding-top: 0;
  padding-bottom: 0;
}

.nav-container {
  height: auto!important;
  height:2.5rem!important;
  margin-top: 1rem;
}

.nav{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}



}

/*Footer*/

.footer{
  padding:2rem 0;
  background-color: rgb(10, 48, 64);
  border-top: 0.2rem solid rgb(217, 126, 41);
}

.footer-center{
  max-width: 1170px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.footer-p{
  color:white;
  text-align: center;
  font-weight: 100;
  font-size: 0.9rem;
}

.footer-link{
  color:rgb(217, 126, 41);
  transition: 0.5s;
}

.footer-link:hover {
  color: rgb(238, 174, 114);
}

.footer-icons{
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icon{
    
    font-size: 2rem;
    margin:0 0.5rem
}

.footer-social-link{
color: rgb(217, 126, 41);
transition: 0.5s;
}

.footer-social-link:hover {
  color: rgb(224, 161, 101);

}

/*Home*/

.home-content{
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5rem;
 padding-top: 5rem;
  gap:2rem;
  align-items: center;
}

.home-social{
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
  
}

.home-social-icon{
  font-size: 2rem;
  /* font-size: 1rem; */
  color: rgb(217, 126, 41);
  transition: 0.5s;
}
.home-social-icon:hover{
  color:rgb(14, 110, 123);
}

.home-title{
  font-size: 2.3rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-family: 'Abyssinica SIL', serif;
  letter-spacing: 0.1rem;
  background-color: rgb(241, 228, 217);
  display: flex;
 justify-content: center;
 align-items: center;
}

.home-smile{
  /* width:2rem;
  height: 2rem; */
  font-size: 2rem;
  margin-left: 1rem;
  display: inline-block;
  color: rgb(217, 126, 41);

  display: flex;
  justify-content: center;
  align-items: center;
}

.title-cover{
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  
  
}

.home-subtitle{
  text-align: center;
  position: relative;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  padding:0 0.5rem;
 
}

/* .home-subtitle::before{
  content: "";
  position: absolute;
  width:4rem;
  height: 0.2rem;
  background-color:rgb(14, 110, 123) ;
  left:0;
  top:1rem;
} */

/* .line{
  content: "";
    position: absolute;
    width: 4rem;
    height: 0.2rem;
    background-color: rgb(14, 110, 123);
    left: 0;
    top: 1rem;
} */

.my-line{
width: 3rem;
  height: 0.2rem;
  background-color: rgb(14, 110, 123);
  /* left: 0; */
  /* top: 1rem; */
  margin-top: -1rem;
 
}


.home-description{
  max-width: 400px;
  margin-bottom: 2rem;
  /* text-align: justify; */
}


.home-img{
  background:  url(../public/images/AWUKO2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;
  height: 20rem;
  width:300px;
  width:20rem ;
  order:1;
  justify-self: center;
  box-shadow: inset 0 0 0 9px rgb(255 255 255/30%); 
  animation: profile-animate 8s ease-in-out infinite 1s;
}

@keyframes profile-animate  {
  0%{
border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50%{
border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100%{
border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

.hello-icon{
  color: rgb(217, 126, 41);
  margin-left: 0.5rem;
}

.hello-btn{
  transition: 0.5s;
  padding:0.3rem 1rem;
 
}
.hello-btn:hover{
  background-color:rgb(14, 110, 123) ;
}

.home-data{
  text-align: center;
}

.home-scroll{
  position: relative;
}

.home-scroll-button{
  font-size: 2rem;
  justify-self: center;
  color:rgb(10, 48, 64);
  transition: 0.5s;
}

.home-scroll-button:hover {
    color: rgb(217, 126, 41);
}

/* .home{
  background: linear-gradient( 0% rgb(201, 225, 239), 100% rgb(164, 197, 215));
background: linear-gradient(to right,rgb(201, 225, 239) 0%, rgb(201, 225, 239) 50%, rgb(179, 212, 229) 50%, rgb(179, 212, 229) 100%);

} */

@media screen and (min-width:800px){
  .home {
      /* background: linear-gradient(0% rgb(201, 225, 239), 100% rgb(164, 197, 215)); */
      background: linear-gradient(to right, rgb(201, 225, 239) 0%, rgb(201, 225, 239) 50%, rgb(179, 212, 229) 50%, rgb(179, 212, 229) 100%);
  
    }
}

.wheel{
  animation: scroll 2s ease infinite;
  display: inline-block;
 
  position: absolute;
  left:50%;
  transform: translateX(-50%);
 
}

@keyframes scroll{

  0%{
margin-top: 0rem;

  }

    25% {
      margin-top: 0.25rem;
  
    }

50%{
margin-top: 0.5rem;

}

75% {
  margin-top: 0.75rem;

}

100%{
  margin-top: 0rem;
}

/* 0% {
  margin-top: 0rem;
} */


}

.scroll-p{
  font-size: 1rem;
      color: rgb(217, 126, 41);
}


/*Breakpoints*/
/*Large Devices*/
@media screen and (max-width:992px) {

.home-content {
    grid-template-columns: 100px repeat(2, 1fr);  
    gap: 1.25rem;
    padding-top: 4rem;
  }

  .home-img{
    width: 15rem;
    height: 15rem;
    box-shadow: inset 0 0 0 8px rgb(255 255 255/30%);
  }
/* .home-subtitle::before{
  width: 100%;
} */
.home-title{
  font-size: 1.8rem;
}

.home-subtitle{
  font-size: 1.2rem;
  padding:0 0.2rem;
  line-height: 1.5rem;
}

}


/*Medium*/
@media screen and (max-width:768px) {

  .home-content {
      grid-template-columns: 0.5fr 3fr; 
    }
.home-img{order: initial;
  justify-self: initial;
  justify-self: center;
}

.home-data{
  margin-top: 2rem;
  grid-column: 1/3;
  justify-content: center;
}

.home-description{
margin: 0 auto;
margin-bottom: 1.5rem;
}
.home-scroll{
  display: none;
}

  }



/*Small*/
@media screen and (max-width:350px) {
.home-img{
  width:12rem;
  height:12rem;
}
}

/*CV*/
.cv-page{
width: 100%;
height: 100vh;
}

.CV-section {
  padding: 3rem 0;
}

.CV-container{
  max-width: 1190px;
}

/*About*/

.about-img{
  width: 100%;
  border-radius: 50%;
  /* justify-self: center; */
  border: 0.4rem solid rgb(152, 190, 200);
}
.about-container{
  /* grid-template-columns: 20rem 1fr;
  gap:3rem;
  align-items: center; */
  display: flex;
  justify-content:space-around;
  align-items: center;
  flex-wrap: wrap;
  /* background-color: aquamarine; */
}

.about-img-container,.about-info{
  /* flex:1 30rem; */
  margin:0.1rem;
}

.about-img-container{
  flex:1 13rem;
  max-width: 13rem;
  
}

.about-data{
  text-align: center;
}
.about-info{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* background-color: blueviolet; */
  flex:2 20rem;
  max-width: 60rem;
}

.about-box{
  flex:1 12rem;
  max-width: 15rem;
  margin:1rem;
  background-color: rgb(232, 239, 246);
  border-radius: 0.3rem;
  padding:1rem;
  box-shadow: 0 0 0.3rem rgb(67, 67, 77);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 3rem;
  transition: 0.5s;
}

@media screen and (max-width:1000px){

  .about-box{
    margin-top: 2rem;
  }
}

@media screen and (max-width:500px) {

   /* .about-info {
    margin-left: 0;
    margin-right: 0;
  } */
}
.about-box:hover {
  
  box-shadow: 0 0 0.5rem rgb(67, 67, 77); 
}

.about-box:hover{
  background-color: rgb(10, 48, 64);
}
.about-box:hover p{
  color:white;
}

.about-box:hover .about-title{
  color:rgb(217, 126, 41);
}

.about-icon-cover {
  border: 0.15rem solid rgb(217, 126, 41);
  background-color: rgb(232, 239, 246);
  border-radius: 50%;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
    top: 0%;
    left:50%;
    transform: translate(-50%,-50% );
    
    
   
}

.about-icon{
  color: rgb(217, 126, 41);
  font-size: 2rem;
  line-height: 2rem;
  display: block;
  margin-top: 0.4rem;
  
}

 .nav.fixed-nav{
    background-color: rgba(10, 48, 64, 0.626);
    /* background-color: rgba(255, 255, 255, 0.426); */
    position: fixed;
    width:100%;
    top:0;
    left:0;
    z-index: 1;
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.fixed-nav .nav-item{
  color: white;
}

.fixed-nav .nav-logo{
  color:white;
}

.fixed-nav .nav-close, .fixed-nav .nav-toggle{
  color: white;
}

/*Skills Container*/

.skills-section, .section-services{
  padding:5rem 0;
}

.skills-container, .services-container{
  width:90vw;
  max-width: 1100px;
  margin:0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
 
}

.skill-category{
  background-color: rgb(232, 239, 246);
  background-color: rgb(10, 48, 64);
  box-shadow: 0 0 0.3rem rgb(67, 67, 77);
  border-radius: 0.3rem;
  padding:1rem;
  flex:1 20rem;
  max-width: 20rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
}

.skill-category:hover{
  box-shadow: 0 0 0.6rem rgb(67, 67, 77);
  background-color: rgb(10, 48, 64);
  background-color: rgb(232, 239, 246);
}




.single-skill{
  display: flex;
  align-items: center;
  /* background-color: blueviolet; */
  margin-top: 0.5rem;
}

.skill-name{
  margin-left: 0.5rem;
  color:rgb(255, 255, 255);
}

.skill-category:hover .skill-name {
color:rgb(10, 48, 64);
}

.skill-h3{
  color: rgb(217, 126, 41);
}

.skill-category:hover .skill-h3 {
    color: rgb(217, 126, 41);
    color: rgb(14, 110, 123);
}

.skill-category:hover .skill-icon {
  color: rgb(10, 48, 64);
}


.skill-icon{
  display: flex;
  color:rgb(255, 255, 255);
}

.skill-top-icon{
  color: rgb(217, 126, 41);
    font-size: 2.5rem;
}

/*Services*/


.single-service {
  background-color: rgb(232, 239, 246);
  /* box-shadow: 0 0 0.3rem rgb(67, 67, 77);
  border-radius: 0.3rem; */
  padding: 1rem;
  flex: 1 20rem;
  max-width: 20rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
}

@media screen and (min-width:780px){
.single-service {
   height: 13rem;
  }

}

.service-h3{
  text-align: center;
}

.service-icon{
  align-self: flex-start;
  font-size: 2.5rem;
}

.service-btn{
  color:rgb(14, 110, 123);
  border:0.15rem solid rgb(14, 110, 123);
  padding:0.3rem 0.6rem;
  margin-top: 1rem;
  display: flex;
 align-items: center;
background-color: rgb(232, 239, 246);
transition: 0.5s;
}

.service-btn:hover {
  color: rgb(255, 255, 255);
  background-color:  rgb(14, 110, 123);
}

.service-btn span{
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
}

.modal-container{
  height:100vh;
  background-color: rgba(7, 31, 35, 0.464);
  width:100%;
  position: fixed;
  top:0;
  left:0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content{
  margin:0.5rem;
  padding:1rem;
  background-color: rgb(232, 239, 246);
  box-shadow: 0 0 0.3rem rgb(67, 67, 77);
    border-radius: 0.3rem;
    text-align: justify;
    max-width: 30rem;
    margin:0 auto;
    margin: 0.5rem;
    position: relative;
    /* transition: 0.5s; */
}

.modal-h4{
  text-align: center;
  color:rgb(14, 110, 123);
}

@media screen and (max-width:350px){
  .modal-h4 {
      margin-top: 2rem;
    }
}

.underline{
  max-width: 10rem;
  height:0.15rem;
  background-color: rgb(217, 126, 41);
  margin: auto;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.modal-close-icon{
  position: absolute;
  top:1rem;
  right:1rem;
  color:rgb(10, 48, 64);
  cursor: pointer;
  font-size: 1.5rem;
  transition: 0.5s;
}

.modal-close-icon:hover {
 
color: rgb(14, 110, 123);

}

.hide-modal{
  display:none;
}
/*Qualification*/

.qualifications-section{
  padding:5rem 0;
  background-color: rgb(240, 240, 240);
}

.qualification-container{
  max-width: 768px;
  max-width: 1000px;
}

.qualifications-tabs{
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.qualification-btn{
  display: flex;
  margin:0.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  align-items: center;
  color:rgb(217, 126, 41);
  transition: 0.5s;
}

.qualification-btn:hover {
  color: rgb(14, 110, 123);
    /* border-bottom: 0.15rem solid rgb(14, 110, 123); */
    
}

.qualification-btn p{
  margin-left: 0.5rem;
}

.qualification-active{
  color: rgb(14, 110, 123);
  /* border-bottom: 0.15rem solid rgb(217, 126, 41); */
}

.qualification-sections{
  display: grid;
  grid-template-columns: 0.5fr;
  justify-content: center;
 
}
.qualification-content{
  display: none;
}

.qualification-content-active {
  display: block;
}

.qualification-data{
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-columns:   max-content 1fr;
  column-gap: 1.5rem;
  /* background-color: aquamarine; */
  min-width: 15rem;
  margin-bottom: 1rem;
 
  

}

.qualification-title{
  font-size: 1rem;
  /* font-size: 0.5rem; */
}

.qualification-subtitle{
  /* margin-top: 6rem; */
  display: inline-block;
  margin-bottom: 0.5rem;
}

@media screen and (max-width:850px){
  /* .qualification-title,.qualification-subtitle {
        font-size: 0.5rem;
    } */
}

.qualification-calendar{
    color: rgb(217, 126, 41);

    display: flex;
    justify-content: center;
    align-items: center;
  
}
.qualification-calendar p{
  margin-left: 0.3rem;
  margin-top: 0.2rem;
}

.qualification-rounder{
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgb(14, 110, 123);
}



.qualification-line{
  display: block;
  width:0.15rem;
  background-color: rgb(14, 110, 123);
 height:100%;
 transform: translate(7px, -7px);
 
}

.exp .qualification-data{
  max-width: 20rem;
  margin:0 auto;
  margin-bottom: 1rem;
}

/*Testimonials*/

.section-testimonials{
  padding:5rem 0;
  background-color: rgb(243, 243, 243);
}

/*Testimonials*/

.reviews-cover{
  background-color: rgb(232, 239, 246);
  padding:3rem 1rem;
}



.review {
  
  padding: 1.5rem 2rem;
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
  transition: var(--transition);
  text-align: center;
max-width: 40rem;
background-color: rgb(246, 246, 246);
border-radius: 0.5rem;
box-shadow: 0 0 0.3rem rgb(7, 96, 111);
margin-left:1rem;
margin-right: 1rem;
margin: 0 auto;
transition: 0.5s;
}

.review:hover {

  box-shadow: 0 0 0.6rem rgb(7, 96, 111);
}

.img-container1 {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

.person-img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
}

.quote-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transform: translateY(25%);
  background:rgb(14, 110, 123);
  color:white;
}

.img-container1::before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgb(217, 126, 41);
  position: absolute;
  top: -0.25rem;
  right: -0.5rem;
  border-radius: 50%;
}

.author {
  margin-bottom: 0.25rem;
}

.job {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: var(--clr-primary-5);
  font-size: 0.85rem;
}

.info {
  margin-bottom: 0.75rem;
}

.prev-btn,
.next-btn {
  color: rgb(217, 126, 41);
  font-size: 1.3rem;
  background: transparent;
  border-color: transparent;
  margin: 0 1rem;
  transition:0.5s;
  cursor: pointer;
}

.prev-btn:hover,
.next-btn:hover {
  color:rgb(159, 92, 29);
}

.random-btn {
  margin-top: 0.5rem;
  background: var(--clr-primary-10);
  color: rgb(217, 126, 41);
  padding: 0.25rem 0.5rem;
  text-transform: capitalize;
  border-radius: var(--radius);
  transition: var(--transition);
  border-color: transparent;
  cursor: pointer;
}

.random-btn:hover {
  background: var(--clr-primary-5);
  color: var(--clr-primary-1);
}

/*Contact*/

.contact-cover {
  /* background-color: rgb(238, 238, 249); */
  /* background-color: rgb(201, 225, 239); */
  padding: 5rem 0;
}

form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  font-size: 16px;

}

input {
  width: 100%;
  height: 35px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  box-shadow: 0 0 0.2rem grey;
}

input:focus {
  border: 2px solid rgb(217, 126, 41);
}

textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  box-shadow: 0 0 0.2rem grey;
}

textarea:focus {
  border: 2px solid rgb(217, 126, 41);
}

label {
  margin-top: 1rem;
  color: rgb(14, 110, 123);

}

input[type="submit"] {
  margin-top: 2rem;
  cursor: pointer;
  background: rgb(217, 126, 41);
  color: white;
  border: none;
  transition: 0.5s;
}

input[type="submit"]:hover{
  background: rgb(155, 90, 29);
}




.contact-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* justify-content: flex-end; */
  align-items: center;
  padding-top: 0;

  max-width: 50rem;
  margin:0 auto;

  
  

}

.contact-slides,
.form-cover {
  flex: 1 30rem;
  max-width: 30rem;
  margin: 1rem;



}

.form-cover{
  max-width: 25rem;
}



.contact-slides-cover .d-block {
  height: 25rem;
}

.contact-cards{

  display: flex;
    flex-wrap: wrap;
    justify-content:flex-end;
    align-items: flex-start;
    flex: 1 15rem ;
   
    
}

@media screen and (min-width:721px) {
  .contact-cards {
    justify-content: center;
    margin-right: -1.5rem;
    /* display: none;   */
  }
}


@media screen and (max-width:720px){
.contact-cards {
  justify-content: center;
    /* margin-right: 0rem; */
    /* display: none;   */
  }
}

@media screen and (min-width:780px) {
.contact-cards .single-service {
    height: auto;
    
  }

}

.contact-cards .service-icon{
  align-self: center;
  color:rgb(217, 126, 41);
}

.contact-p{
  color: rgb(14, 110, 123);
}

/*Portfolio*/

.portfolio-section{

    padding: 5rem 0;
    width: 90vw;
      max-width: 1100px;
      margin: 0 auto;
      
      
}

.portfolio-container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

}


.portfolio-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

}
.p-btn{
  color:white;
  background-color: rgb(14, 110, 123);
  margin:0.5rem;
  padding:0.3rem 0.6rem;
  border-radius: 0.3rem;
  transition: 0.5s;
  flex:1 10rem;
  max-width: 15rem;
  min-width: 12rem;
}
.p-btn:hover, .p-btn-active {
  background-color: rgb(217, 126, 41);
}



.portfolio-contents{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.porfolio-content{
  flex:1 20rem;
  margin:1rem;
  max-width: 20rem;
  background-color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 0.2rem;
  box-shadow: 0 0 0.3rem rgb(16, 60, 86);
transition: 0.5s;
}

.porfolio-content img{
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.porfolio-content:hover {
 transform: scale(1.03);
  box-shadow: 0 0 0.5rem rgb(16, 60, 86);
 
}

.porfolio-content  p{
  /* padding:0.2rem 0; */
  padding-bottom: 0.6rem;
 
}

.porfolio-link{
color: rgb(16, 60, 86);
text-decoration: none;
}
.success-box{
  background-color: rgb(186, 218, 186);
  border:0.15rem solid rgb(79, 172, 79);
  padding:0.3rem 1rem;
  color:rgb(79, 172, 79);
  margin:0 auto;
  margin-top: 1rem;
  /* padding-top: 2rem; */
}

.error-box{
  background-color: rgb(244, 233, 233);
    border: 0.15rem solid rgb(149, 45, 45);
    padding: 0.3rem 1rem;
    color: rgb(149, 45, 45);
    margin: 0 auto;
    margin-top: 1rem;
    /* padding-top: 2rem; */
}

     /*Back to top*/
  
  
     .back-top-cover {
       border: none;
       cursor: pointer;
       width: 3.5rem;
       height: 3.5rem;
       display: flex;
       justify-content: center;
       align-items: center;
       position: fixed;
       right: 2rem;
       bottom: 2rem;
       background-color: rgb(217, 126, 41);
       border-radius: 50%;
       box-shadow: 0 0 0.3rem grey;
        box-shadow: 0 0 0.3rem rgb(255, 255, 255);
       z-index: 3;
       transition: 0.5s;
       animation: top 2s linear infinite;
     }
  
     .back-top-cover:hover {
       background-color: rgb(153, 86, 24);
     }
  
     @keyframes top {
       0% {
         transform: scale(1);
       }
  
       50% {
         transform: scale(1.2);
       }
  
       100% {
         transform: scale(1);
       }
  
  
  
     }
  
     .top-arrow {
       /* margin-top: -0.5rem; */
       color: white;
       font-size: 2rem;
       display: inline-block;
       line-height: 2rem;
     }

/*WhatsApp Icon*/

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 40px;

  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.whatsapp_float:hover {
  background-color: #167539;
}

.whatsapp-icon {
  color: white;
  width:40px;
  height: 40px;
}

.whatsapp-icon:hover {
  color: white;
}

